// useLazyQueryApi.js

import {useLazyQuery} from '@apollo/client'
import {gql} from 'graphql-tag'
import {useDispatch} from 'react-redux'
import {toast} from 'react-toastify'
import {actionsUsers} from '../setup/redux/rootReducer'

const useLazyQueryApi = (toastmessage: any, query: any) => {
  const dispatch = useDispatch()
  const [getDataQuery, {loading, error, data}]: any = useLazyQuery(query)

  const fetchData = (variables: any) => {
    getDataQuery({
      variables: variables,
    })
  }

  if (error) {
    console.log('Error>>>', JSON.stringify(error))
    const sessionExpirePattern = /session\s*expire/i

    if (sessionExpirePattern.test(error?.networkError?.result?.errors[0]?.message)) {
      if (toastmessage) {
        toast.error('Session Expired! Please login again.')
        setTimeout(() => {
          dispatch(actionsUsers.logout())
        }, 3000)
      }
    } else {
      toast.error(`${error?.message}`)
    }
  }

  return {
    fetchData,
    loading,
    error,
    data,
  }
}

export default useLazyQueryApi
