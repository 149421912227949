/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'

import {OTP} from './components/OTP'
import {MPIN} from './components/MPIN'
import '../../../../App.css'
import {Business} from './components/Business'
import {toAbsoluteUrl} from '../../../../_ePulse/helpers'

const AuthLayout = () => {
  useEffect(() => {
    document.body.style.backgroundImage = 'none'
    return () => {}
  }, [])

  return (
    // <div
    //   className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-cover bgi-attachment-fixed'
    //   style={{
    //     backgroundImage: `url(${toAbsoluteUrl('/media/ePulse/loginBG.png')})`,
    //     backgroundSize: ' 100%',
    //   }}
    // >
    //   {/* begin::Content */}
    //   <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
    //     {/* begin::Logo */}
    //     <a href='#' className='mb-12'>
    //       <img
    //         alt='Logo'
    //         src={toAbsoluteUrl('/media/ePulse/logo3.png')}
    //         className='theme-dark-show h-65px'
    //       />
    //       <img
    //         alt='Logo'
    //         src={toAbsoluteUrl('/media/ePulse/logo3.png')}
    //         className='theme-light-show h-65px'
    //       ></img>
    //     </a>
    //     {/* end::Logo */}
    //     {/* begin::Wrapper */}
    //     <div className='w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
    //       <Outlet />
    //     </div>
    //     {/* end::Wrapper */}
    //   </div>
    //   {/* end::Content */}
    //   {/* begin::Footer */}
    //   <div className='d-flex flex-center flex-column-auto p-10'>
    //     <div className='d-flex align-items-center fw-semibold fs-6'>
    //       <a href='#' className='text-muted text-hover-primary px-2'>
    //         About
    //       </a>

    //       <a href='#' className='text-muted text-hover-primary px-2'>
    //         Contact
    //       </a>

    //       <a href='#' className='text-muted text-hover-primary px-2'>
    //         Contact Us
    //       </a>
    //     </div>
    //   </div>
    //   {/* end::Footer */}
    // </div>
    <div
      className='d-flex justify-content-between h-100'
      style={{
        // backgroundImage: `url(${toAbsoluteUrl('/media/ePulse/loginBG.png')})`,
        background: 'linear-gradient(269.89deg, #29AAA9 0.09%, #564EA2 99.92%)',
        // backgroundSize: ' 100%',
      }}
    >
      {/* begin::left Content */}
      <div className='w-100 login-banner'>
        <h2>Healthcare made simple</h2>
        <ul>
          <li>Doctors</li>
          <li>Pharmacies</li>
          <li>Scan & Diagnostics Centers</li>
          <li>Consumers</li>
        </ul>
      </div>

      {/* begin::Content */}
      <div className='d-flex h-100 login-section'>
        {/* begin::Logo */}
        {/* <a href='#' className='mb-12'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/ePulse/logo3.png')}
            className='theme-dark-show h-45px'
          />
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/ePulse/logo3.png')}
            className='theme-light-show h-65px'
          ></img>
        </a> */}
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className='d-flex flex-column align-items-center justify-content-center w-500px bg-body px-15 pt-15'>
          <a href='#' className='mb-12'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/ePulse/logo3.png')}
              className='theme-dark-show h-45px'
            />
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/ePulse/logo3.png')}
              className='theme-light-show h-65px'
            ></img>
          </a>
          <Outlet />
          <div className='d-flex flex-center flex-column-auto p-10 login-footer'>
            <div className='d-flex align-items-center'>
              <a href='#' className='text-muted text-hover-primary'>
                About
              </a>

              {/* <a href='#' className='text-muted text-hover-primary px-2'>
            Contact
          </a> */}

              <a href='#' className='text-muted text-hover-primary'>
                Contact Us
              </a>
            </div>
          </div>
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}
      {/* <div className='d-flex flex-center flex-column-auto p-10'>
        <div className='d-flex align-items-center fw-semibold fs-6'>
          <a href='#' className='text-muted text-hover-primary px-2'>
            About
          </a>

          <a href='#' className='text-muted text-hover-primary px-2'>
            Contact
          </a>

          <a href='#' className='text-muted text-hover-primary px-2'>
            Contact Us
          </a>
        </div>
      </div> */}
      {/* end::Footer */}
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route path='otp' element={<OTP />} />
      <Route path='mpin' element={<MPIN />} />
      <Route path='business' element={<Business />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
