import {gql} from '@apollo/client'

export const GET_INVENTORY_LIST = gql`
  query getInventoryList($page: Int!, $limit: Int!) {
    getInventoryList(listFilter: {page: $page, limit: $limit}) {
      data {
        id
        productId
        productName
        mrpPerUnit
        batchNumber
        mrp
        expriryDate
        remainingQuantity
        quantity
        __typename
      }
      total
      pageSize
      page
      totalPages
      __typename
    }
  }
`

export const GET_VENDOR_LIST = gql`
  query getVendorList($page: Int!, $limit: Int!) {
    getVendorList(listFilter: {page: $page, limit: $limit}) {
      data {
        id
        mobileNumber
        name
        firmName
        __typename
email
zip
      }
      total
      pageSize
      page
      totalPages
      __typename
    }
  }
`

export const GET_PATIENT_LIST = gql`
  query getBusinessPatientPaginationList($page: Int!, $limit: Int!) {
    getBusinessPatientPaginationList(listFilter: {page: $page, limit: $limit}) {
      data {
        id
        mobileNumber
        name
        profilePhoto
        __typename
      }
      total
      pageSize
      page
      totalPages
      __typename
    }
  }
`

export const GET_SAMPLE_PATIENT_FILE = gql`
  query getPatientSampleFile {
    getPatientSampleFile
  }
`
export const GET_SAMPLE_VENDOR_FILE = gql`
  query GetVendorSampleFile {
    getVendorSampleFile
  }
`
export const GET_SAMPLE_INVENTORY_FILE = gql`
  query getInventorySampleFile {
    getInventorySampleFile
  }
`
