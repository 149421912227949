import {gql} from '@apollo/client'

export const GET_PDF_PRESCRIPTION = gql`
  query getDoctorPrescription($doctorId: ID!) {
    getDoctorPrescription(doctorId: $doctorId) {
      id
      formKeyValues
      templateName
      doctorId
      image
      createdDate
      clinicLogo
      doctorSignature
      createdBy
    }
  }
`

export const SET_PDF_PRESCRIPTION = gql`
  mutation setDoctorPrescriptionData(
    $doctorId: ID!
    $formKeyValues: JSON!
    $doctorSignature: FileInput
    $clinicLogo: FileInput
  ) {
    setDoctorPrescriptionData(
      prescriptionInput: {
        doctorId: $doctorId
        formKeyValues: $formKeyValues
        doctorSignature: $doctorSignature
        clinicLogo: $clinicLogo
      }
    )
  }
`

export const CHANGE_PRESCRIPTION = gql`
  mutation changePrescriptionTemplate($doctorId: ID!, $templateName: String!) {
    changePrescriptionTemplate(doctorId: $doctorId, templateName: $templateName)
  }
`
