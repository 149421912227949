/* eslint-disable import/no-anonymous-default-export */
// reducer is function who has access for state& action
// eslint-disable-next-line no-unused-vars
import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  auth: {},
  user: {
    mobile_number: '',
    _id: '',
  },
  browsingDetails: {},
}

const userReducer = createSlice({
  name: 'login',
  initialState,
  reducers: {
    updateUser(state, action) {
      state.user = action.payload
    },

    updatebrowsingDetails(state, action) {
      state.browsingDetails = action.payload
    },

    // updateToken(state, action) {
    //   state.token = action.payload
    // },

    updateState(state, action) {
      state.auth = action.payload
    },

    logout: (state) => {
      state.auth = null
    },
  },
  // extraReducers: {
  //   [fetchUserName.fulfilled]: (state, action) => {
  //     state.userId = action.payload
  //   },
  //   [fetchUserName.pending]: (state, action) => {
  //     state.userId = 'Loading!!!'
  //   },
  //   [fetchUserName.rejected]: (state, action) => {
  //     state.userId = 'Try Again!!!'
  //   },
  // },
})
export const actionsUsers = userReducer.actions
export default userReducer.reducer
