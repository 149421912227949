/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'



import {useMutation, useQuery} from '@apollo/client'
import {useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import useApi from '../../../hooks/useQuery'
import { GET_PDF_PRESCRIPTION, SET_PDF_PRESCRIPTION } from './GraphQLQueries/PDFPrescription'
import useMutationApi from '../../../hooks/useMutation'
import { success } from '../../../data/Status/success'
import { useDropzone } from 'react-dropzone'
import { ToastMessageContainer } from '../../../_ePulse/layout/components/ToastMessageContainer'
import { ChangeTemplate } from '../DoctorModule/modules/settingsMain/appSettings/PDF/modals/ChangeTemplateModal'
import { Preview } from '../DoctorModule/modules/settingsMain/appSettings/PDF/modals/Preview'
import { toAbsoluteUrl } from '../../../_ePulse/helpers'
import { GET_ACTIVE_BUSINESS, UPDATE_PRODUCT_SLOT } from './GraphQLQueries/Patients'


export function PDFTab() {
  const dataUser: any = useSelector((state) => {
    return state
  })

  const [checked, setChecked] = React.useState(false)
  const [modalState, setModalState] = useState(false)
  const [previewmodalState, setPreviewModalState] = useState(false)
  const [name, setName] = useState('')
  const [qualification, setqualification] = useState('')
  const [mobileNumber1, setmobilenumber1] = useState('')
  const [mobileNumber2, setmobileNumber2] = useState('')
  const [email, setemail] = useState('')
  const [address, setaddress] = useState('')
  const [hospital, sethospital] = useState('')
  const [website, setwebsite] = useState('')
  const [selectedTemplate, setSelectedTemplate] = useState<any>()
  const [logoImage, setImages] = useState<any>()
  const [isloading, setLoading] = useState(false)
  const [pdfData, setPDFdata] = useState<any>()
  const [mris, setmri] = useState<any>('')
  const [ultrasounds, setultrasound] = useState<any>()
  const [labtests, setlabtest] = useState<any>()
  const [ctscans, setctscan] = useState<any>()
  const [xrays, setxray] = useState<any>()
  const [mrid, setmrid] = useState<any>('m')
  const [ultrasoundd, setultrasoundd] = useState<any>('m')
  const [labtestd, setlabtestd] = useState<any>('m')
  const [ctscand, setctscand] = useState<any>('m')
  const [xrayd, setxrayd] = useState<any>('m')
  const [signatureImage, setsignatureImage] = useState<any>()
  const [ProductSlotsInput, SetProductSlotsInput] = useState<any>()
  const [mriId, setMriId] = useState(null);
  const [ultrasoundId, setUltrasoundId] = useState(null);
  const [labTestId, setLabTestId] = useState(null);
  const [ctScanId, setCtScanId] = useState(null);
  const [xrayId, setXrayId] = useState(null);
const[showBusiness,setShowBusiness] =useState([])
  const {
    data: respdfData,
    loading: loading4,
    error: error4,
    refetch: refetch4,
  } = useQuery(GET_ACTIVE_BUSINESS)

  useEffect(() => {
    if (loading4) {
      console.log('Loading...');
      return;
    }
  
    if (error4) {
      console.log('Error:', error4);
      return;
    }

    if (respdfData) {
      console.log(respdfData, 'Data sssreceived');
      setShowBusiness(respdfData.getBusinessInActiveProductOffering)
      respdfData.getBusinessInActiveProductOffering.forEach((product: {
          appointmentDurationTime: any
          isActive: boolean ;productValue: string; id: React.SetStateAction<null> 
}) => {
        if (product.productValue === 'mri'&& product.isActive) {
          setMriId(product.id);
          setmri(product?.appointmentDurationTime)
        } else if (product.productValue === 'ultrasound' && product.isActive) {
          setUltrasoundId(product.id);
          setultrasound(product?.appointmentDurationTime)

        } else if (product.productValue === 'lab_test' && product.isActive) {
          setLabTestId(product.id);
          setlabtest(product?.appointmentDurationTime)

        } else if (product.productValue === 'ct_scan' && product.isActive) {
          setCtScanId(product.id);
          setctscan(product?.appointmentDurationTime)

        } else if (product.productValue === 'xray' && product.isActive) {
          setXrayId(product.id);
          setxray(product?.appointmentDurationTime)

        }
      });
    }
  }, [respdfData, loading4, error4]);
  
  const [errors, setErrors] = useState({});
  const validateFields = () => {
    console.log(xrays,'xrayy')
    const newErrors:any = {};
    if (!mris&&mriId || ( parseInt(mris) <= 0)) {
        newErrors.mris = 'Field Cannot Be empty Or Less than 1';
    }
    if (!ultrasounds&&ultrasoundId || (parseInt(ultrasounds) <= 0)) {
        newErrors.ultrasounds = 'Field Cannot Be empty Or Less than 1';
    }
    if (!labtests&&labTestId || ( parseInt(labtests) <= 0)) {
        newErrors.labtests = 'Field Cannot Be empty Or Less than 1';
    }
    if (!ctscans&&ctScanId || ( parseInt(ctscans) <= 0)) {
        newErrors.ctscans = 'Field Cannot Be empty Or Less than 1';
    }
    if (!xrays&&xrayId || ( parseInt(xrays) <= 0)) {
        newErrors.xrays = 'Field Cannot Be empty Or Less than 1';
    }
    


    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const [
    updateProductslot,
    { data: data2, loading: loading3, error: error3 },
  ] = useMutation(UPDATE_PRODUCT_SLOT);
  const adddBusiness = async () => {
    if (!validateFields()) {
        toast.error('Field Cannot Be empty Or Less than 1');
        return;
      }
    const productSlotsInput = [
        {
          productId: mriId,
          appointmentDurationTime: parseInt(mris),
          appointmentDurationUnit: mrid
        },
        {
          productId: ultrasoundId,
          appointmentDurationTime: parseInt(ultrasounds),
          appointmentDurationUnit: ultrasoundd
        },
        
        {
          productId: ctScanId,
          appointmentDurationTime: parseInt(ctscans),
          appointmentDurationUnit: ctscand
        },
      
      ];
    console.log(productSlotsInput,'eigjhk')
    await updateProductslot({
      variables: {
        ProductSlotsInput: [
            {
                productId: mriId,
                appointmentDurationTime: parseInt( mris),
                appointmentDurationUnit: mrid
              },
              {
                productId: ultrasoundId,
                appointmentDurationTime: parseInt( ultrasounds),
                appointmentDurationUnit: ultrasoundd
              },
              {
                productId: labTestId,
                appointmentDurationTime: parseInt( labtests),
                appointmentDurationUnit: labtestd
              },
              {
                productId: ctScanId,
                appointmentDurationTime: parseInt( ctscans),
                appointmentDurationUnit: ctscand
              },
              {
                productId: xrayId,
                appointmentDurationTime: parseInt( xrays),
                appointmentDurationUnit: xrayd
              }
          
          ],
      },
      onCompleted: async (data) => {
          let data1 = await data
          console.log(data1,'sghsghshgshgshg')
          toast.success(data?.
            updateProductSlots
            )
       
         
         

        
      },
      onError: (errors) => {
console.log(errors,'errorr')
       toast.error('Error Updating')
      },
    });
  };



  return (
    <div className='d-flex flex-row w-100'>
      <ToastMessageContainer />
      {modalState && (
        <ChangeTemplate
          modalState={modalState}
          setModalState={(value: boolean) => setModalState(value)}
          selectedTemplate={(value: any) => setSelectedTemplate(value)}
          name={name}
          qualification={qualification}
          address={address}
          email={email}
          mobileNumber1={mobileNumber1}
          mobileNumber2={mobileNumber2}
          hospital={hospital}
          website={website}
          doctorImage={logoImage[0]?.preview}
          logoImage={logoImage?.base64 || logoImage}
          signature={signatureImage?.base64 || signatureImage}
        />
      )}
      {previewmodalState && (
        <Preview
          modalState={previewmodalState}
          selectedTemplate={selectedTemplate}
          setModalState={(value: boolean) => setPreviewModalState(value)}
          // selectedTemplate={(value: any) => setSelectedTemplate(value)}
          name={name}
          qualification={qualification}
          address={address}
          email={email}
          mobileNumber1={mobileNumber1}
          mobileNumber2={mobileNumber2}
          hospital={hospital}
          website={website}
          doctorImage={logoImage[0]?.preview}
          logoImage={logoImage?.base64 || logoImage}
          signature={signatureImage?.base64 || signatureImage}
        />
      )}
      <div className='d-flex w-100 rounded ms-2 bg-light px-6 pt-6 pb-10 flex-column'>
     
        <div className=' mt-4 px-6'>
            {showBusiness?.map((data: {
                productValue: string
                isActive: any, productName: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined 
})=>(
                <>
                {data?.isActive?<>
                    <div className='d-flex flex-column '>
                <span className='fs-5 ms-4 fw-bold mb-2'>
                 {data?.productName}
                </span>
              </div>
            
              <div className='d-flex flex-row'>
        <div className='d-flex flex-column col form-block'>
          <span className='form-label required'>Appointment Duration Default Time(in min)</span>
          <input
       type={'number'}
            className='form-control  px-3'
            // min={5}
            // max={60}
            value={data?.productValue=='mri'?mris:data?.productValue=='ultrasound'?ultrasounds:data?.productValue==='lab_test'?labtests:data?.productValue=='ct_scan'?ctscans:xrays}
            onChange={(e) => {data?.productValue=='mri'?setmri(e.target.value):data?.productValue=='ultrasound'?setultrasound(e.target.value):data?.productValue==='lab_test'?setlabtest(e.target.value):data?.productValue=='ct_scan'?setctscan(e.target.value):setxray(e.target.value)}}
            disabled={!data?.isActive}
          />
            
        </div>
        <div className='d-flex flex-column col form-block'>
          <span className='form-label required'>Appointment Duration Unit</span>

          <select
            className='form-control'
            value={data?.productValue=='mri'?mrid:data?.productValue=='ultrasound'?ultrasoundd:data?.productValue==='lab_test'?labtestd:data?.productValue=='ct_scan'?ctscand:xrayd}
            onChange={(e) => {data?.productValue=='mri'?setmrid(e.target.value):data?.productValue=='ultrasound'?setultrasoundd(e.target.value):data?.productValue==='lab_test'?setlabtestd(e.target.value):data?.productValue=='ct_scan'?setctscand(e.target.value):setxrayd(e.target.value)}}
            disabled={!data?.isActive}
          >
            <option key={'m'} value={'m'}>
              Minutes
            </option>
           
          </select>
        </div>
      </div>
                </>
                :<></>}
                
          
                </>
            ))}
      
      
         
        
        </div>

       
        <div className='btn-row my-4'>
          {/* <button
            className='border border-dark my-4 bg-light py-2 fs-6 rounded-pill w-100px'
            onClick={() => {}}
          >
            Go Back
          </button> */}
          <button className='ePulse-btn-primary' type='button' onClick={()=>adddBusiness()}>
            {!isloading && 'Save'}
            {isloading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>

    
      </div>
      
    </div>
  )
}
