/* eslint-disable jsx-a11y/alt-text */
import {FC} from 'react'
import clsx from 'clsx'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {HeaderUserMenu, QuickLinks, ThemeModeSwitcher} from '../../../partials'
import {useLayout} from '../../core'
import {useSelector} from 'react-redux'

const itemClass = 'ms-1 ms-lg-3',
  btnClass = 'btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px',
  userAvatarClass = 'symbol-30px symbol-md-40px',
  btnIconClass = 'svg-icon-2'

const Topbar: FC = () => {
  const {config} = useLayout()

  const dataUser: any = useSelector((state) => {
    return state
  })

  return (
    <div className='d-flex align-items-stretch justify-self-end flex-shrink-0'>
      {/* <div
        className={clsx('flex row align-items-center me-5')}
        data-kt-menu-trigger='click'
        data-kt-menu-attach='parent'
        data-kt-menu-placement='bottom-start'
      > */}
      {/* begin::Menu wrapper */}
      {/* <div
          className={clsx('btn btn-icon btn-active-light-primary btn-custom', btnClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        > */}
      {/* <div className='d-flex flex-row w-150px justify-content-between btn bg-white rounded p-2'>
          <span className='fs-6 fw-bold'>Quick Actions</span>
          <img
            src={toAbsoluteUrl('/media/ePulse/arrow_dropdown.png')}
            className='h-60 align-self-center'
          />
        </div>
        <QuickLinks /> */}
      {/* </div> */}

      {/* end::Menu wrapper */}
      {/* </div> */}

      {/* Search */}
      {/* <div className={clsx('d-flex align-items-stretch', itemClass)}>
        <Search />
      </div> */}
      {/* Activities */}
      {/* <div className={clsx('d-flex align-items-center', itemClass)}> */}
      {/* begin::Drawer toggle */}
      {/* <div
          className={clsx('btn btn-icon btn-active-light-primary btn-custom', btnClass)}
          id='kt_activities_toggle'
        >
          <KTSVG path='/media/icons/duotune/general/gen032.svg' className={btnIconClass} />
        </div> */}
      {/* end::Drawer toggle */}
      {/* </div> */}

      {/* NOTIFICATIONS */}
      {/* <div className={clsx('d-flex align-items-center', itemClass)}> */}
      {/* begin::Menu- wrapper */}
      {/* <div
          className={clsx('btn btn-icon btn-active-light-primary position-relative ', btnClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <KTSVG path='/media/icons/duotune/general/gen022.svg' className={btnIconClass} />
        </div>
        <HeaderNotificationsMenu /> */}
      {/* end::Menu wrapper */}
      {/* </div> */}

      {/* <div className={clsx('d-flex align-items-center', itemClass)}> */}
      {/* begin::Menu wrapper */}
      {/* <div
          className={clsx(
            'btn btn-icon btn-active-light-primary btn-custom position-relative',
            btnClass
          )}
          id='kt_drawer_notification_toggle'
        >
          <KTSVG path='/media/ePulse/icons/header/bell.svg' className={btnIconClass} /> */}

      {/* <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'></span> */}
      {/* </div> */}
      {/* end::Menu wrapper */}
      {/* </div> */}

      {/* CHAT */}
      {/* <div className={clsx('d-flex align-items-center', itemClass)}> */}
      {/* begin::Menu wrapper */}
      {/* <div
          className={clsx(
            'btn btn-icon btn-active-light-primary btn-custom position-relative',
            btnClass
          )}
          id='kt_drawer_chat_toggle'
        >
          <KTSVG path='/media/ePulse/icons/header/chat.svg' className={btnIconClass} /> */}

      {/* <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'></span> */}
      {/* </div> */}
      {/* end::Menu wrapper */}
      {/* </div> */}

      {/* Quick links */}

      {/* begin::Theme mode */}
      {/* <div className={'d-flex align-items-center '}>
        <ThemeModeSwitcher toggleBtnClass='btn-active-light-primary w-30px h-30px w-md-40px h-md-40px' />
      </div> */}
      {/* end::Theme mode */}

      {/* begin::User */}
      <div className={clsx('user-badge-block')} id='kt_header_user_menu_toggle'>
        {/* begin::Toggle */}
        <div
          className={clsx('user-avatar')}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='epulse' />
        </div>
        <span className='user-name'>
          Hi, Dr. {dataUser?.auth?.profileData?.firstName}
          <br />
          <span className='user-role'>{dataUser?.auth?.role}</span>
        </span>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>

      {/* end::User */}

      {/* begin::Aside Toggler */}
      {config.header.left === 'menu' && (
        <div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
          </div>
        </div>
      )}
    </div>
  )
}

export {Topbar}
