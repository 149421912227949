import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_ePulse/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'

import {PharmaDashboardWrapper} from '../MasterModules/DoctorModule/pages/dashboard/PharmaDashboardWrapper'
import {getCSSVariableValue} from '../../_ePulse/assets/ts/_utils'
import {WithChildren} from '../../_ePulse/helpers'
import ProfilePages from '../MasterModules/PharmaModule/modules/patient/patientPage'

const PhamraRoutes = () => {
  const SwitchBusinessPage = lazy(
    () => import('../common/modules/switchBusiness/SwitchBusinessPage')
  )

  const InventoryPage = lazy(
    () => import('../MasterModules/PharmaModule/modules/inventory/inventoryPage')
  )
  const VendorPage = lazy(() => import('../MasterModules/PharmaModule/modules/vendor/vendorPage'))
  const PatientPage = lazy(
    () => import('../MasterModules/PharmaModule/modules/patient/patientPage')
  )

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='dashboard' element={<PharmaDashboardWrapper />} />
        <Route path='patient' element={<ProfilePages />} />


        <Route
          path='switch_business/*'
          element={
            <SuspensedView>
              <SwitchBusinessPage />
            </SuspensedView>
          }
        />

        <Route
          path={'/inventory/*'}
          element={
            <SuspensedView>
              <InventoryPage />
            </SuspensedView>
          }
        />
        <Route
          path={'/vendor/*'}
          element={
            <SuspensedView>
              <VendorPage />
            </SuspensedView>
          }
        />
        <Route
          path={'/patients/*'}
          element={
            <SuspensedView>
              <PatientPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PhamraRoutes}
