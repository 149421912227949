/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useSelector, useDispatch} from 'react-redux'

import {useLocation} from 'react-router-dom'
import {GET_BUSINESS_LIST_AUTH, LOGIN_BUSINESS_ON_WEB} from '../../../GraphQLQuries/Auth'
import useApi from '../../../../../hooks/useQuery'
import useMutationApi from '../../../../../hooks/useMutation'
import { actionsUsers } from '../../../../../setup/redux/rootReducer'

const loginSchema = Yup.object().shape({
  business: Yup.string().required('Select Business').notOneOf(['default'], 'Select Business'),
})

const initialValues = {
  business: '',
}

export function Business() {
  const location = useLocation()
  const dispatch = useDispatch()
  const [businessList, setBusinessList] = useState<any>([])

  const {
    data: data1,
    loading: loading1,
    error: error2,
    refetch: refetch2,
  } = useApi(true, GET_BUSINESS_LIST_AUTH, {})

  useEffect(() => {
    if (data1) {
      const res = data1?.getWebBusinessList
      setBusinessList(res)
    }
  }, [data1])

  const {browserUniqueId, ipAddress, userAgent, browserName}: any = location?.state
  const userData: any = useSelector((state) => {
    return state
  })

  const [loadingLoader, setLoading] = useState(false)

  const {createData, data, loading, error} = useMutationApi(LOGIN_BUSINESS_ON_WEB)

  //   const [loginBusiness, {data, loading, error}] = useMutation(LOGIN_BUSINESS_ON_WEB, {
  //     onCompleted: (response: any) => {
  //         dispatch(actionsUsers.updateState(response.verifyWebAdminOtp))
  //         dispatch(
  //           actionsUsers.updateUser({
  //             mobile_number: userData.user.mobile_number,
  //             _id: response.verifyWebAdminOtp.userId,
  //           })
  //         )
  //     },
  //   })

  const [status, setstatus] = useState(false)
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      setLoading(true)
      try {
        // await loginBusiness({
        //   variables: {
        //     userRoleId: formik.values.business,
        //     deviceDetail: {
        //       ipAddress: ipAddress,
        //       userAgent: userAgent,
        //       browserUniqueId: browserUniqueId,
        //       browserName: browserName,
        //     },
        //   },
        // })

        const res = await createData({
          userRoleId: formik.values.business,
          browserUniqueId: browserUniqueId,
          ipAddress: ipAddress,
          userAgent: userAgent,
          browserName: browserName,
        })

        dispatch(actionsUsers.updateState(res?.data?.loginBusinessOnWeb))
        dispatch(
          actionsUsers.updateUser({
            mobile_number: userData.user.mobile_number,
            _id: res?.data.loginBusinessOnWeb.userId,
          })
        )

        setLoading(false)
      } catch (error) {
        setstatus(true)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>
          Select Business
          {/* <a className='' style={{color: '#000FD0'}}>
            ePulse
          </a> */}
        </h1>
        {/* <div className='text-gray-400 fw-bold fs-4'>
          New Here?{' '}
          <Link to='/auth/registration' className=' fw-bolder' style={{color: '#000FD0'}}>
            Create an Account
          </Link>
        </div> */}
      </div>
      {/* begin::Heading */}

      {status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{error?.message}</div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='col form-block'>
        <span className='form-label required'>Select Business</span>
        <div className='d-flex flex-column col'>
          <select className='form-control' {...formik.getFieldProps('business')}>
            <option key={'default'} value={'default'}>
              Select Business
            </option>
            {businessList?.map((item: any) => {
              return (
                <option key={item.id} value={item.id}>
                  {item.associatedName}({item.businessType})
                </option>
              )
            })}
          </select>
          {formik.touched.business && formik.errors.business && (
            <div className='fv-plugins-message-container'>
              <span className='text-danger'>{formik.errors.business}</span>
            </div>
          )}
        </div>
      </div>

      {/* end::Form group */}
      {/* begin::Action */}
      <div className='text-center mt-4'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg  w-100 mb-5 rounded-pill text-white'
          //   disabled={formik.isSubmitting || !formik.isValid}
          style={{backgroundColor: '#000FD0'}}
        >
          {!loadingLoader && <span className='indicator-label'>Continue</span>}
          {loadingLoader && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}
