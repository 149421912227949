import {gql} from '@apollo/client'

export const UPLOAD_INVENTORY = gql`
  mutation UploadeInventory($baseUrl: String!) {
    uploadeInventory(baseUrl: $baseUrl)
  }
`

export const UPLOAD_VENDOR = gql`
  mutation UploadeVendor($baseUrl: String!) {
    uploadeVendor(baseUrl: $baseUrl)
  }
`
export const IMPORT_SCAN_PATIENTS = gql`
  mutation importScanCenterPatients($fileDataString: String!) {
    importScanCenterPatients(fileDataString: $fileDataString) 
  }
`;
export const IMPORT_PHARMACY_PATIENTS = gql`
  mutation importPharmacyPatients($fileDataString: String!) {
    importPharmacyPatients(fileDataString: $fileDataString) 
  }
`;