import moment from 'moment'

export const policy_types = [
  {
    id: 'Privacy',
    label: 'Privacy',
  },
  {
    id: 'TermsCondition',
    label: 'Terms & Condition',
  },
  {
    id: 'ApplicationPolicy',
    label: 'Application Policy',
  },
  {
    id: 'RefundPolicy',
    label: 'Refund Policy',
  },
  {
    id: 'EULA (End user license agreement)',
    label: 'EULA (End user license agreement)',
  },
]

export const step_name = [
  {
    id: 'phone',
    label: 'Phone',
  },
  {
    id: 'email',
    label: 'Email',
  },
  {
    id: 'doctor_profile',
    label: 'Doctor Profile',
  },
  {
    id: 'Business Address',
    label: 'Business Address',
  },
  {
    id: 'mci_verification',
    label: 'MCI Verification',
  },
  {
    id: 'doctor_type',
    label: 'Doctor Type',
  },
]

export const business_Type = [
  {
    id: 'Consumer',
    label: 'Consumer',
  },
  {
    id: 'Doctor',
    label: 'Doctor',
  },
  {
    id: 'Pharma',
    label: 'Pharma',
  },
  {
    id: 'ScanCenter',
    label: 'ScanCenter',
  },
]

export const options = [
  {
    label: 'image/*',
    value: 'image/*',
  },
  {
    label: 'application/vnd.ms-excel',
    value: 'application/vnd.ms-excel',
  },
  {
    label: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    value: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  },
  {
    label: 'application/docx',
    value: 'application/docx',
  },
  {
    label: 'application/pdf',
    value: 'application/pdf',
  },
  {
    label: 'text/plain',
    value: 'text/plain',
  },
  {
    label: 'application/msword',
    value: 'application/msword',
  },
  {
    label: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    value: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  },
  {
    label: 'application/zip',
    value: 'application/zip',
  },
  {
    label: 'application/x-zip-compressed',
    value: 'application/x-zip-compressed',
  },
  {
    label: 'application/x-compressed',
    value: 'application/x-compressed',
  },
  {
    label: 'multipart/x-zip',
    value: 'multipart/x-zip',
  },
  {
    label: '.xlsx',
    value: '.xlsx',
  },
  {
    label: 'video/x-flv',
    value: 'video/x-flv',
  },
  {
    label: 'video/mp4',
    value: 'video/mp4',
  },
  {
    label: 'application/x-mpegURL',
    value: 'application/x-mpegURL',
  },
  {
    label: 'video/MP2T',
    value: 'video/MP2T',
  },
  {
    label: 'video/3gpp',
    value: 'video/3gpp',
  },
  {
    label: 'video/quicktime',
    value: 'video/quicktime',
  },
  {
    label: 'video/x-msvideo',
    value: 'video/x-msvideo',
  },
  {
    label: 'video/x-ms-wmv',
    value: 'video/x-ms-wmv',
  },
  {
    label: 'application/sla',
    value: 'application/sla',
  },
  {
    label: '.stl',
    value: '.stl',
  },
]

export const currency = [
  {
    label: 'INR',
    value: 'INR',
  },
  {
    label: 'Dollar',
    value: 'Dollar',
  },
  {
    label: 'Euro',
    value: 'Euro',
  },
]

export const setting_tabs = [
  // {
  //   title: 'Profile Setting',
  //   path: '/application_setting/profile_setting',
  // },
  // {
  //   title: 'Clinic Setting',
  //   path: '/application_setting/clinic_setting',
  // },
  // {
  //   title: 'Account Settings',
  //   path: '/application_setting/account_setting',
  // },
  // {
  //   title: 'Theme Settings',
  //   path: '/application_setting/theme_setting',
  // },
  // {
  //   title: 'RX Designer',
  //   path: '/application_setting/rx_designer',
  // },
  {
    title: 'Prescription Designer',
    path: '/doctor/application_setting/prescription_designer',
  },
  // {
  //   title: 'Appointment Setting',
  //   path: '/doctor/application_setting/appointment_setting',
  // },
]
export const setting_tabs_scan = [
  // {
  //   title: 'Profile Setting',
  //   path: '/application_setting/profile_setting',
  // },
  // {
  //   title: 'Clinic Setting',
  //   path: '/application_setting/clinic_setting',
  // },
  // {
  //   title: 'Account Settings',
  //   path: '/application_setting/account_setting',
  // },
  // {
  //   title: 'Theme Settings',
  //   path: '/application_setting/theme_setting',
  // },
  // {
  //   title: 'RX Designer',
  //   path: '/application_setting/rx_designer',
  // },
  {
    title: 'Booking Slot Setting',
    path: '/booking_slot_scan',
  },
]

export const excel_headers = [
  {
    key: 'firstName',
    value: 'First Name',
  },

  {
    key: 'lastName',
    value: 'Last Name',
  },
  {
    key: 'countryCode',
    value: 'Country Code',
  },
  {
    key: 'mobileNumber',
    value: 'Mobile Number',
  },
  {
    key: 'gender',
    value: 'Gender',
  },
  {
    key: 'dob',
    value: 'Date Of Birth',
  },
  {
    key: 'bloodGroup',
    value: 'Blood Group',
  },
  {
    key: 'address',
    value: 'Address',
  },
  {
    key: 'zipCode',
    value: 'ZIP Code',
  },
  {
    key: 'city',
    value: 'City',
  },
  {
    key: 'district',
    value: 'District',
  },
  {
    key: 'state',
    value: 'State',
  },
  {
    key: 'country',
    value: 'Country',
  },
]

export const updateDateFormat = (date: any) => {
  const currentTime = moment() // Get the current time
  // Parse the input date and format it without time
  const parsedDate = moment(date, 'ddd MMM DD YYYY HH:mm:ss ZZ')
  const formattedDate = parsedDate.format('YYYY-MM-DD') // Format the date
  // Combine the formatted date with the current time
  const combinedDateTime = moment(`${formattedDate} ${currentTime.format('HH:mm:ss')}`).utc()
  return combinedDateTime.format('YYYY-MM-DDTHH:mm:ss.SSSZ')
}

export const checkMobileNoValid = (number: any) => {
  if (/^\d*$/.test(number) && number.length <= 10) {
    return true
  } else {
    return false
  }
}

export const checkzipcodeValid = (number: any) => {
  if (/^\d*$/.test(number) && number.length <= 6) {
    return true
  } else {
    return false
  }
}

export const validateMobileNumber = (value: any) => {
  const mobileNumberRegex = /^[0-9]{10}$/
  return mobileNumberRegex.test(value)
}

export const validateCountryCode = (value: any) => {
  const countryCodeRegex = /^(91|1)$/
  return countryCodeRegex.test(value)
}

export const validateGender = (value: any) => {
  const genderRegex = /^(Male|Female|Others)$/
  return genderRegex.test(value)
}

export const validateBloodGroup = (value: any) => {
  // const bloodGroupRegex = /^(A+|A-|B+|B-|AB+|AB-|O+|O-)$/
  // return bloodGroupRegex.test(value)
  const bloodGroupRegex = /^(A|B|AB|O)[+-]$/
  return bloodGroupRegex.test(value)
}

export const validateName = (value: any) => {
  const nonEmptyStringRegex = /^[a-zA-Z0-9-/\s]+$/
  return nonEmptyStringRegex.test(value)
}

export const validateAddress = (value: any) => {
  const nonEmptyStringRegex = /^[a-zA-Z0-9 !@#$%^&*()_\-+=\{\}\[\];':".,\/<>?\\s]+$/
  return nonEmptyStringRegex.test(value)
}

export const validateDate = (value: any) => {
  return moment(value, 'YYYY-MM-DD', true).isValid()
}

export const validateZipCode = (value: any) => {
  const zipCodeRegex = /^[0-9]{5,6}$/
  return zipCodeRegex.test(value)
}

export function convertToCamelCase(inputString: any) {
  // Remove symbols and split the string into words
  let words = inputString
    ?.toLowerCase()
    .replace(/[^a-zA-Z0-9]+/g, ' ')
    .split(' ')

  // Capitalize the first letter of each word
  for (let i = 0; i < words?.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1)
  }

  // Join the words with spaces
  return words?.join(' ')
}
